import Vue from 'vue';
import axios from 'axios';
import { Message } from 'element-ui';
import dayjs from 'dayjs';
import { debounce } from 'lodash';
import Dialog from '@/components/ykc-ui/dialog';
import { sessionClear, sessionGetItem } from '@/utils';
import router from '@/router';

const baseURL = '/stage-crm/';

const service = axios.create({
  baseURL,
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
    // Authorization: JSON.parse(sessionGetItem('token')),
  },
  timeout: 60000, // 请求超时时间
});

const buildFormData = data => {
  const formData = new FormData();
  Object.entries(data).forEach(([k, v]) => {
    formData.append(k, v);
  });
  return formData;
};

const debounceWarning = debounce(() => Message.error('登录过期'), 1000);

/** **** request拦截器==>对请求参数做处理 ***** */
service.interceptors.request.use(
  config => {
    const sessionToken = sessionGetItem('token');
    if (!sessionToken) {
      sessionClear();
      // localClear();
      router.replace('/login');
    } else {
      config.headers.Authorization = sessionToken;
    }
    return config;
  },
  error => {
    // 请求错误处理
    Promise.reject(error);
  }
);

/** **** response拦截器==>对响应做处理 ***** */
service.interceptors.response.use(
  res => {
    if (res.data?.resultCode === '00100008') {
      debounceWarning();
      sessionClear();
      router.replace('/login');
      return Promise.reject(res);
    }
    return res.data;
  },
  res => {
    // Message.error('服务异常，请联系系统管理员！');
    return Promise.reject(res);
  }
);

service.all = axios.all;

service.offlineExport = options => {
  const { data, message, url } = options;
  const msg = message || '请求已发出，请前往系统管理导出记录中查看';
  const expUrl = url || 'URL_COMMONOFFLINEEXPORT';
  Dialog({
    dialogType: 'feedback',
    showFooter: true,
    showTitle: false,
    desc: '确认导出？',
    onConfirm(done) {
      done();
      service({
        url: expUrl,
        data: {
          body: data,
        },
      })
        .then(() => {
          Dialog(msg);
        })
        .catch(() => {});
    },
  });
};

/** 文件导出封装方法 */
export const exportFile = config => {
  service({
    url: config.url,
    data: config.data,
    responseType: 'blob',
  }).then(res => {
    if (res.type === 'application/json') {
      const read = new FileReader();
      read.readAsText(res, 'utf-8');
      read.onload = ({ target }) => {
        const { header } = JSON.parse(target.result);
        Message.error(header.resultDesc);
      };
    } else {
      const objectUrl = URL.createObjectURL(res);
      const timestamp = dayjs().format('YYYYMMDDHHmmss');
      // window.open(objectUrl);
      const elink = document.createElement('a');
      elink.download = `${config.fileName}${timestamp}.csv`;
      elink.style.display = 'none';
      elink.href = objectUrl;
      document.body.appendChild(elink);
      elink.click();
      URL.revokeObjectURL(elink.href); // 释放URL 对象
      document.body.removeChild(elink);
    }
  });
};

/** 文件导入封装 */
export const importFile = config => {
  return new Promise((resolve, reject) => {
    service({
      ...config,
      data: buildFormData({
        accountNo: localStorage.accountNo,
        accountName: localStorage.accountName,
        ...config.data,
      }),
    })
      .then(res => {
        if (res.header.resultCode === '0') {
          resolve(res.body);
        } else {
          Message.error(res.header.resultDesc);
          reject(res);
        }
      })
      .catch(res => {
        reject(res);
      });
  });
};

service.spread = axios.spread;

Vue.prototype.$axios = service;
export default service;
